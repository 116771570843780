<template>
  <v-card>
    <v-row class="mt-2 mx-2 pt-0">
      <v-col
        cols="6"
        xs="6"
        class="px-4 pt-2 mt-1 pb-0 mb-0"
      >
        <v-select
          v-model="selectedTransactionFilter"
          outlined
          dense
          :items="transaction_types"
          label="Filter Transactions By"
        ></v-select>
      </v-col>
      <v-col
        cols="6"
        xs="6"
        class="px-4 pt-2 mt-1 mt-1 pb-0 mb-0"
      >
        <v-select
          v-model="selectedTopUp"
          outlined
          dense
          item-value="id"
          item-text="username"
          :items="topup_list"
          label="Select TopUp"
        ></v-select>
      </v-col>

      <v-col
        cols="5"
        xs="5"
        class="text-left mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedStartDate"
          dense
          type="date"
          label="Start Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-right mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedEndDate"
          dense
          type="date"
          label="End Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="2"
        xs="2"
        class="text-right mt-0 pt-0"
      >
        <spinner v-if="loading" class="text-right"></spinner>
        <v-btn
          v-else
          icon small
          class="primary"
          @click="getTransactions()"
        >
          <font-awesome-icon icon="fa-solid fa-search"/>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-title class="">
      <span class="me-3">({{ transactions.length }}) Transactions</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <h3 class="ml-3 text--center">
      <span v-if="deposits_total">Total Deposit: {{ deposits_total }} F CFA</span> 
      <span v-if="withdrawal_total"> Total Withdrawal: {{ withdrawal_total }} F CFA</span> 
      <span v-if="withdrawal_fee"> | Withdrawal Fees: {{ withdrawal_fee }} F CFA</span> 
      <span v-if="interrupt_total">Total Interrupt: {{ interrupt_total }} F CFA</span> 
      <span v-if="interrupt_fee"> | Interrupt Fees: {{ interrupt_fee }} F CFA</span> 
    </h3>
    <v-data-table
      :headers="headers"
      :items="transactions"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.username`]="{item}">
        <div class="d-flex flex-column" @click="getMoreInfo(item.id)" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</span>
          <small v-if="item.campaign">{{ item.campaign }}</small>
          <small v-else>{{ item.loan }}</small>
        </div>
      </template>
      <template #[`item.amount`]="{item}">
        {{ item.amount }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[item.status]"
          class="font-weight-medium"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template #[`item.date_time`]="{item}">
        {{ item.date_time }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Interrupt: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      selectedTransactionFilter: null,
      transaction_types: ["Deposits (Loans)", "Deposits (Campaigns)", "Withdrawals", "Interrupts"],
      selectedTopUp: null,
      topup_list: [],
      transactions: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      deposits_total: '',
      withdrawal_total: '',
      withdrawal_fee: '',
      interrupt_total: '',
      interrupt_fee: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Transactions"
      this.getTopUps()
  },

  methods:{
    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/')
        .then(response => {
          this.topup_list = response.data  // get the data and fill into campaigns
          console.log(this.topup_list)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getTransactions(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (this.selectedTransactionFilter === ''){
        this.selectedTransactionFilter = 'All'
      }
      if (!this.selectedTopUp|| this.selectedTopUp === ''){
        this.selectedTopUp = 'All'
      }
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/get/topup/transactions/'+this.selectedTransactionFilter+'/'+this.selectedTopUp+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.transactions = response.data['response_data']  // get the data and fill into campaigns
          this.deposits_total = response.data['deposit_total']
          this.withdrawal_total = response.data['withdrawal_total']
          this.withdrawal_fee = response.data['withdrawal_fee']
          this.interrupt_total = response.data['interrupt_total']
          this.interrupt_fee = response.data['interrupt_fee']
          console.log(this.transactions)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },
  },
}
</script>
